import { all } from 'redux-saga/effects';
import authSaga from './auth';
import ProfileSaga from './profile';
import CommonSaga from './common';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    ProfileSaga(),
    CommonSaga(),
  ]);
}
