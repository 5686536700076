import {
  takeEvery, fork, all, call, put,
} from 'redux-saga/effects';
import { get } from 'lodash';
import { message } from 'antd';
import ProfileActions, { ProfileActionTypes } from 'states/actions/profile';
import profileAPI from 'states/sagas/api/profile';

function* handleGetList({ payload }) {
  try {
    const result = yield call(profileAPI.getList, payload);
    const data = get(result, 'data');
    if (get(data, 'code') !== 200) {
      throw data;
    }
    yield put(ProfileActions.onGetListSuccess(data));
  } catch (error) {
    message.error(get(error, 'msg') || get(error, 'message'));
    yield put(ProfileActions.onGetListError(error));
  }
}

function* handleGetDetail({ id }) {
  try {
    const result = yield call(profileAPI.getDetail, { id });
    const data = get(result, 'data', {});
    if (data.code !== 200) throw data;
    yield put(ProfileActions.onGetDetailSuccess(data));
  } catch (error) {
    message.error(get(error, 'msg', 'Lỗi khi lấy dữ liệu!'));
    yield put(ProfileActions.onGetDetailError(error));
  }
}

/**
 *
 * create
 */
function* handleCreate({ payload, filters, callback }) {
  try {
    const result = yield call(profileAPI.create, payload);
    const data = get(result, 'data', {});
    if (data.code !== 200) throw data;
    message.success('Create profile success!');
    if (callback) {
      callback();
    }
    yield put(ProfileActions.onCreateSuccess(data));
    yield put(ProfileActions.onGetList(filters));
  } catch (error) {
    console.log(error);
    message.error(get(error, 'msg', 'Error when create profile!'));
    yield put(ProfileActions.onCreateError(error));
  }
}

/**
 *
 * create
 */
function* handleCreateDetail({ payload, filters, callback }) {
  try {
    const result = yield call(profileAPI.createDetail, payload);
    const data = get(result, 'data', {});
    if (data.code !== 200) throw data;
    message.success('Create profile success!');
    if (callback) {
      callback();
    }
    yield put(ProfileActions.onCreateSuccess(data));
    yield put(ProfileActions.onGetDetail(filters));
  } catch (error) {
    console.log(error);
    message.error(get(error, 'msg', 'Error when create profile!'));
    yield put(ProfileActions.onCreateError(error));
  }
}

/**
 *
 * update
 */
function* handleUpdate({ payload, filters, callback }) {
  try {
    const result = yield call(profileAPI.update, payload);
    const data = get(result, 'data', {});
    if (data.code !== 200) throw data;
    message.success('Update profile success!');
    if (callback) {
      callback();
    }
    yield put(ProfileActions.onUpdateSuccess(data));
    yield put(ProfileActions.onGetList(filters));
  } catch (error) {
    console.log(error);
    message.error(get(error, 'msg', 'Error when Update profile!'));
    yield put(ProfileActions.onUpdateError(error));
  }
}

/**
 *
 * delete
 */
function* handleDelete({ id, filters, callback }) {
  try {
    const result = yield call(profileAPI.delete, { id });
    const data = get(result, 'data', {});
    if (data.code !== 200) throw data;
    message.success('Delete profile success!');
    if (callback) {
      callback();
    }
    yield put(ProfileActions.onDeleteSuccess(data));
    // yield put(ProfileActions.onGetList(filters));
  } catch (error) {
    console.log(error);
    message.error(get(error, 'msg', 'Error when Delete profile!'));
    yield put(ProfileActions.onDeleteError(error));
  }
}

/**
 *
 */
export function* watchGetList() {
  yield takeEvery(ProfileActionTypes.GET_LIST, handleGetList);
}

export function* watchGetDetail() {
  yield takeEvery(ProfileActionTypes.GET_DETAIL, handleGetDetail);
}

export function* watchCreate() {
  yield takeEvery(ProfileActionTypes.CREATE, handleCreate);
}

export function* watchCreateDetail() {
  yield takeEvery(ProfileActionTypes.CREATE_DETAIL, handleCreateDetail);
}

export function* watchUpdate() {
  yield takeEvery(ProfileActionTypes.UPDATE, handleUpdate);
}
export function* watchDelete() {
  yield takeEvery(ProfileActionTypes.DELETE, handleDelete);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetDetail),
    fork(watchCreate),
    fork(watchCreateDetail),
    fork(watchUpdate),
    fork(watchDelete),
  ]);
}
