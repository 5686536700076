export const DATE_TIME_FORMAT = "DD/MM/YYYY";
export const LOCATION_KEY = "location_id";

export const LocalStorage = {
  Token: "remix_cms_token",
  Device: "remix_device_init",
  StockId: "remix_stock_id",
  LocationId: "remix_location_id",
  Firebase: "remix_firebase_token",
};

const isDev = process.env.NODE_ENV === "development";
const hostname = "http://localhost";

export const endpoint = isDev
  ? process.env.APP_API_DEV
  : process.env.APP_API_PROD;

export const url_hostname = isDev
  ? `${hostname}:${process.env.PORT_MAIN}`
  : process.env.APP_URL_HOSTNAME;

export const url_crm = isDev
  ? `${hostname}:${process.env.PORT_CRM}`
  : process.env.APP_URL_CRM;

export const url_logistic = isDev
  ? `${hostname}:${process.env.PORT_LOGISTIC}`
  : process.env.URL_LOGISTIC;

export const url_hr = isDev
  ? `${hostname}:${process.env.PORT_HUMAN_RESOURCE}`
  : process.env.APP_URL_HUMAN_RESOURCE;

export const url_accountant = isDev
  ? `${hostname}:${process.env.PORT_ACCOUNTANT}`
  : process.env.APP_URL_ACCOUNTANT;

export const url_so = isDev
  ? `${hostname}:${process.env.PORT_SALES_ORDER}`
  : process.env.APP_URL_SALES_ORDER;

export const url_po = isDev
  ? `${hostname}:${process.env.PORT_PURCHASE_ORDER}`
  : process.env.APP_URL_PURCHASE_ORDER;

export const url_stock = isDev
  ? `${hostname}:${process.env.PORT_STOCK}`
  : process.env.APP_URL_STOCK;

export const url_auth = isDev
  ? `${hostname}:${process.env.PORT_AUTH}`
  : process.env.APP_URL_AUTH;

export const ApiUrl = {};

export const config = {
  google: {
    clientID: "",
    keyGMap: "",
  },
  fbConfig: {
    appId: "",
    version: "v1.0",
  },
  hasHeader: false,
  hasMobile: true,
  domain: endpoint,
  templates: ["remix"],
  languages: ["vn"],
  app: {},
  hostFileManage: endpoint,
  uploadKey: "9074c259a7", // Live: e92eb8b75c || Test: 9074c259a7
  appId: 2,
};
