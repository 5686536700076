/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import {
  Menu, Input, Rate, Button,
} from 'antd';
import {
  NavLink, Link, useLocation, useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector, userState } from 'react-redux';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import { get } from 'lodash';

import UserInfo from 'components/UserInfo';
import { formatNumber, formatCurrency } from 'utils/formatValue';
import { onLogout, onGetProfileSuccess } from 'states/actions/auth';

import data from './data';
import './SlideBar.scss';

const { SubMenu } = Menu;

export default function Header({ openMenu }) {
  const location = useLocation();
  const profile = useSelector((state) => state.auth.profile) || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [rate, setRate] = useState(4.9);

  const renderSubMenu = (item) => (
    <SubMenu key={item.key} title={item.text} icon={item.icon}>
      {item.subItem.map(renderMenu)}
    </SubMenu>
  );

  const renderMenu = (item) => {
    if (item.subItem) return renderSubMenu(item);
    return (
      <Menu.Item key={item.key} icon={item.icon}>
        <NavLink to={item.to}>{item.text}</NavLink>
      </Menu.Item>
    );
  };

  return (
    <>
      <Menu selectedKeys={[location.pathname.substr(1)]} mode="vertical">
        {data.map(renderMenu)}
      </Menu>
    </>
  );
}
