/* eslint-disable no-return-await */

import {
  all, fork, takeEvery, call, put,
} from 'redux-saga/effects';
import { get } from 'lodash';
import { message } from 'antd';
import {
  LOGIN,
  DEVICE_INIT,
  onDeviceInitSuccess,
  onLoginSuccess,
  onLoginError,
  onDeviceInitError,
  GET_PROFILE,
  onGetProfileSuccess,
  GET_PROFILE_SUCCESS,
  onGetProfileError,
  LOGOUT,
} from 'states/actions/auth';
import { LocalStorage, endpoint } from 'constants/common';
import AuthApi from './api/auth';


// effect
function* handleLogin({ payload }) {
  try {
    const result = yield call(AuthApi.login, payload);
    if (result.code !== 200) {
      message.error(result.msg);
      throw result;
    }
    const token = get(result, 'data.token');
    window.localStorage.setItem(LocalStorage.Token, token);
    yield put(onLoginSuccess(result));
  } catch (error) {
    console.log('Login error', error);
    yield put(onLoginError(error));
  }
}

function* handleDeviceInit(action) {
  try {
    const result = yield call(AuthApi.getDeviceCode);
    if (result.code === 400) {
      message.error(result.msg);
      throw result;
    }
    window.localStorage.setItem(
      LocalStorage.Device,
      JSON.stringify(result.data),
    );
    yield put(onDeviceInitSuccess(result.data));
  } catch (error) {
    console.log('Init device error', error);
    yield put(onDeviceInitError(error));
  }
}

function* handelGetProfile() {
  try {
    const result = yield call(AuthApi.getProfile);
    const data = get(result, 'data');
    if (data.code !== 200) {
      console.log('data', data);
      throw data;
    }
    yield put({
      type: GET_PROFILE_SUCCESS,
      dataProfile: result.data.data,
    });
  } catch (error) {
    console.log('Can\'t get profile', error);
    yield put(onGetProfileError(error));
  }
}

function* handleLogout() {
  try {
    const result = yield call(AuthApi.logout);
    console.log('logout success', result);
  } catch (error) {
    console.log('Logout error', error);
  }
}

function* watchLogin() {
  yield takeEvery(LOGIN, handleLogin);
}

function* watchDeviceInit() {
  yield takeEvery(DEVICE_INIT, handleDeviceInit);
}

function* watchGetProfile() {
  yield takeEvery(GET_PROFILE, handelGetProfile);
}
function* watchLogout() {
  yield takeEvery(LOGOUT, handleLogout);
}

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchDeviceInit),
    fork(watchGetProfile),
    fork(watchLogout),
  ]);
}
