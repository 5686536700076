import {
  takeEvery, fork, all, call, put,
} from 'redux-saga/effects';
import { get } from 'lodash';
import { message } from 'antd';
import OptionActions, {
  OptionActionTypes,
} from 'states/actions/common/option';
import OptionApi from 'states/sagas/api/common/option';

function* handleGetList({ payload }) {
  try {
    const result = yield call(OptionApi.getList, payload);
    const data = get(result, 'data');
    if (get(data, 'code') !== 200) {
      throw data;
    }
    yield put(OptionActions.onGetListSuccess(data));
  } catch (error) {
    message.error(get(error, 'msg') || get(error, 'message'));
    yield put(OptionActions.onGetListError(error));
  }
}

function* handleGetDetail({ payload }) {
  try {
    const result = yield call(OptionApi.getDetail, payload);
    const data = get(result, 'data', {});
    if (data.code !== 200) throw data;
    yield put(OptionActions.onGetDetailSuccess(data));
  } catch (error) {
    message.error(get(error, 'msg', 'Lỗi khi lấy dữ liệu!'));
    yield put(OptionActions.onGetDetailError(error));
  }
}

/**
 *
 * create
 */
function* handleCreate({ payload, filters, callback }) {
  try {
    const result = yield call(OptionApi.create, payload);
    const data = get(result, 'data', {});
    if (data.code !== 200) throw data;
    message.success('Thêm dữ liệu thành công!!');
    if (callback) {
      callback();
    }
    yield put(OptionActions.onCreateSuccess(data));
    yield put(OptionActions.onGetList(filters));
  } catch (error) {
    console.log(error);
    message.error(get(error, 'msg', 'Lỗi trong quá trình thêm dữ liệu!'));
    yield put(OptionActions.onCreateError(error));
  }
}

/**
 *
 * update
 */
function* handleUpdate({ payload, filters, callback }) {
  try {
    const result = yield call(OptionApi.update, payload);
    const data = get(result, 'data', {});
    if (data.code !== 200) throw data;
    message.success('Cập nhật dữ liệu thành công!');
    if (callback) {
      callback();
    }
    yield put(OptionActions.onUpdateSuccess(data));
    const request = {
      params: {
        ...filters,
        limit: 10,
        page: 1,
      },
    };

    yield put(OptionActions.onGetList(request));
  } catch (error) {
    console.log(error);
    message.error(get(error, 'msg', 'Lỗi trong quá trình cập nhật dữ liệu!'));
    yield put(OptionActions.onUpdateError(error));
  }
}

/**
 *
 * delete
 */
function* handleDelete({ payload, filters, callback }) {
  try {
    const result = yield call(OptionApi.delete, payload);
    const data = get(result, 'data', {});
    if (data.code !== 200) throw data;
    message.success('Xóa dữ liệu thành công!');
    if (callback) {
      callback();
    }
    yield put(OptionActions.onDeleteSuccess(data));
    const request = {
      params: {
        ...filters,
        limit: 10,
        page: 1,
      },
    };

    yield put(OptionActions.onGetList(request));
  } catch (error) {
    console.log(error);
    message.error(get(error, 'msg', 'Lỗi khi xóa dữ liệu!'));
    yield put(OptionActions.onDeleteError(error));
  }
}

/**
 *
 */
export function* watchGetList() {
  yield takeEvery(OptionActionTypes.GET_LIST, handleGetList);
}

export function* watchGetDetail() {
  yield takeEvery(OptionActionTypes.GET_DETAIL, handleGetDetail);
}

export function* watchCreate() {
  yield takeEvery(OptionActionTypes.CREATE, handleCreate);
}
export function* watchUpdate() {
  yield takeEvery(OptionActionTypes.UPDATE, handleUpdate);
}
export function* watchDelete() {
  yield takeEvery(OptionActionTypes.DELETE, handleDelete);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetDetail),
    fork(watchCreate),
    fork(watchUpdate),
    fork(watchDelete),
  ]);
}
