import _ from 'lodash';
import api from 'states/drivers';
import { LocalStorage } from 'constants/common';
import { TOKEN_ERROR, onDeviceInit } from 'states/actions/auth';
import AuthApi from 'states/sagas/api/auth';

export default function createApiAuthMiddle({ dispatch, getState }) {
  return (next) => (action) => {
    const authToken = window.localStorage.getItem(LocalStorage.Token);
    let deviceCode = '';
    if (
      action.payload
      && (action.payload.code === 401 || action.payload.code === 403)
      && action.type !== '@@router/LOCATION_CHANGE'
    ) {
      window.localStorage.removeItem(LocalStorage.Device);
      if (action.payload.code === 4403) {
        AuthApi.getDeviceCode().then((result) => {
          window.localStorage.setItem(
            LocalStorage.Device,
            JSON.stringify(result.data),
          );
        });
      }
      return next({
        type: TOKEN_ERROR,
      });
    }
    try {
      const device = JSON.parse(
        window.localStorage.getItem(LocalStorage.Device),
      );
      deviceCode = device.device_code;
    } catch (error) {
      //
    }
    api.changeHeaders({
      authorization: authToken ? `Bearer ${authToken}` : '',
      'device-code': deviceCode,
    });
    return next(action);
  };
}
