import { endpoint } from 'constants/common';
import api from '../../drivers';


const AuthApi = {
  getDeviceCode: async () => await api
    .post(`${endpoint}/device/init`, {
      device_type: 0,
    })
    .then((result) => result.data),
  login: async (params) => await api
    .post(`${endpoint}/auth/login`, params)
    .then((result) => result.data),
  updateDeviceToken: async ({ deviceToken, deviceType }) => await api.put(`${endpoint}/auth/device-token`, {
    device_type: deviceType,
    device_token: deviceToken,
  }),
  logout: async () => await api.get(`${endpoint}/auth/logout`),
  getProfile: async (param) => await api.get(`${endpoint}/auth/profile`, param),
};

export default AuthApi;
