import React, { useState } from "react";
import {
  UserOutlined,
  SettingOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { onLogout, onGetProfileSuccess } from "states/actions/auth";
import * as COMMON from "constants/common";

import "./UserInfo.scss";

export default function UserInfo() {
  //   const [data, setProfile] = useState("profile");
  // console.log("item ->", setProfile);
  const profile = useSelector((state) => state.auth.profile) || {};
  const dispatch = useDispatch();
  const history = useHistory();

  const onSignout = () => {
    const pathName = window.location.href;
    if (pathName.indexOf("login") === -1) {
      window.location.href = `${COMMON.url_auth}/#/login`;
    }

    // dispatch(onLogout());
    window.localStorage.clear();
  };

  const menu_user = (
    <Menu>
      <Menu.Item>{profile.username}</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={onSignout}>
        Đăng xuất
      </Menu.Item>
    </Menu>
  );

  const menu_setting = (
    <Menu>
      <Menu.Item>Chờ cập nhật</Menu.Item>
    </Menu>
  );

  return (
    <div className="user-info">
      <Dropdown overlay={menu_setting} trigger={["click"]}>
        <Button className="btn-menu-dropdown">
          <SettingOutlined />
        </Button>
      </Dropdown>
      <Dropdown overlay={menu_setting} trigger={["click"]}>
        <Button className="btn-menu-dropdown">
          <NotificationOutlined />
        </Button>
      </Dropdown>
      <Dropdown overlay={menu_user} trigger={["click"]}>
        <Button className="btn-user">
          <div className="circle">
            {profile.avatar ? (
              <img src={profile.avatar} alt="" />
            ) : (
              <UserOutlined />
            )}
          </div>
          <span className="user-name">{profile.username}</span>
        </Button>
      </Dropdown>
    </div>
  );
}