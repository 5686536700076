import React from 'react';

export default function MainBlock({ className, children, preview }) {
  const classNames = ['main-block'];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <div className="scroll">
        <div className="main-block__inner">{children}</div>
      </div>
      {preview}
    </div>
  );
}
