import { get, omit, cloneDeep } from 'lodash';
import { LocationActionTypes } from 'states/actions/common/location';

const init = {
  loading: true,
  detail: null,
  processing: false,
  location_id: null,
};

function handleUpdate({ state, action }) {
  const list = cloneDeep(state.list);
  const detailData = get(action, 'payload.data');
  const index = list.findIndex((i) => i.id === detailData.id);
  if (index !== -1) {
    list[index] = { ...list[index], ...detailData };
  }
  return {
    ...state,
    processing: false,
    list,
    detail: {
      ...state.detail,
      data: { ...get(state, 'detail.data'), ...detailData },
    },
  };
}

export default function (state = init, action) {
  switch (action.type) {
    case LocationActionTypes.INIT_LOCATION:
      return {
        ...state,
        location_id: action.payload,
      };

    case LocationActionTypes.CLEAR_DETAIL:
      return {
        ...state,
        detail: null,
        loadingDetail: true,
      };

    case LocationActionTypes.CLEAR_STATE:
      return {
        ...init,
      };

    case LocationActionTypes.GET_LIST:
      return {
        ...state,
        loading: true,
      };

    case LocationActionTypes.GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        apiResultGetList: omit(get(action, 'payload'), ['data']),
      };

    case LocationActionTypes.GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: get(action, 'payload.data.data', []),
        metadata: omit(get(action, 'payload.data'), ['data']),
        apiResultGetList: omit(get(action, 'payload'), ['data']),
      };

    case LocationActionTypes.GET_DETAIL:
      return {
        ...state,
        loadingDetail: true,
        detail: null,
      };

    case LocationActionTypes.GET_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetail: false,
        detail: action.payload,
      };

    case LocationActionTypes.GET_DETAIL_ERROR:
      return {
        ...state,
        loadingDetail: false,
        detail: action.payload,
      };

    case LocationActionTypes.CREATE:
    case LocationActionTypes.UPDATE:
    case LocationActionTypes.DELETE:
      return {
        ...state,
        processing: true,
      };

    case LocationActionTypes.CREATE_ERROR:
    case LocationActionTypes.UPDATE_ERROR:
    case LocationActionTypes.DELETE_ERROR:
      return {
        ...state,
        processing: false,
      };
    case LocationActionTypes.UPDATE_SUCCESS:
      return handleUpdate({ state, action });

    case LocationActionTypes.CREATE_SUCCESS:
    case LocationActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
}
