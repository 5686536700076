import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MyLoadable from 'utils/MyLoadable';
import SiteUrl from 'constants/url';

const LoginPage = MyLoadable({
  loader: () => import('./Login'),
});

export default function MainRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={LoginPage} />
      <Route path="/login" component={LoginPage} />
      <Redirect to="/login" />
    </Switch>
  );
}
