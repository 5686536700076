import { endpoint } from 'constants/common';
import api from 'states/drivers';

const LocationEndpoint = `${endpoint}/locations`;

const LocationApi = {
  getList: async ({ params }) => await api.get(`${LocationEndpoint}`, params),
  getDetail: async ({ id }) => await api.get(`${LocationEndpoint}/${id}`),
  create: async ({ params }) => await api.post(`${LocationEndpoint}`, params),
  update: async ({ id, params }) => await api.put(`${LocationEndpoint}/${id}`, params),
  delete: async ({ id }) => await api.delete(`${LocationEndpoint}/${id}`),
};

export default LocationApi;
