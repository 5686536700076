import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';

import { HTML5Backend } from 'react-dnd-html5-backend';

import 'antd/dist/antd.css';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import store, { history } from 'states/store';
import 'styles/styles.scss';
import ErrorBoundary from 'containers/ErrorBoundary';

import Root from './Root';

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <DndProvider
          backend={TouchBackend}
          options={{
            enableTouchEvents: true,
            enableMouseEvents: true,
            enableHoverOutsideTarget: true,
          }}
        >
          <ErrorBoundary>
            <Root />
          </ErrorBoundary>
        </DndProvider>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
