import React, { useEffect, useMemo } from "react";
import { message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEmpty, omit, get } from "lodash";
import jwtDecode from "jwt-decode";

import getFilterParams from "utils/getFilterParams";
import * as COMMON from "constants/common";
import { onDeviceInit, onGetProfile, onLogout } from "states/actions/auth";
import LocationActions from "states/actions/common/location";

import Routes from "./Routes";

function Root() {
  const history = useHistory();
  const location = useLocation();

  const { search } = location;
  const filters = useMemo(() => omit(getFilterParams(search), "query"), [
    search,
  ]);

  const dispatch = useDispatch();
  let device = {};

  try {
    const tokenStock = get(filters, "token");
    const locationLocal = window.localStorage.getItem(process.env.LOCATION_KEY);

    if (locationLocal) {
      dispatch(LocationActions.onInitLocation(Number(locationLocal)));
    }

    if (tokenStock) {
      window.localStorage.setItem(COMMON.LocalStorage.Token, tokenStock);
    }

    const deviceToken = get(filters, "device");

    if (deviceToken) {
      const data = {
        device_code: deviceToken,
      };
      window.localStorage.setItem(
        COMMON.LocalStorage.Device,
        JSON.stringify(data)
      );
    }

    try {
      device = JSON.parse(
        window.localStorage.getItem(COMMON.LocalStorage.Device)
      );
    } catch (error) {
      device = {};
    }
  } catch (err) {
    console.error(err);
  }

  const token =
    window.localStorage.getItem(COMMON.LocalStorage.Token) ||
    get(filters, "token");

  useEffect(() => {
    try {
      const decodedToken = jwtDecode(token);
      console.log("decodedToken", decodedToken);
      if (isEmpty(decodedToken)) {
        const msg = "Tài khoản chưa được đăng nhập trên hệ thống";
        message.error(msg);
        window.localStorage.removeItem(COMMON.LocalStorage.Token);
        throw new Error(msg);
      }
      if (new Date().getTime() > decodedToken.exp * 1000) {
        window.localStorage.removeItem(COMMON.LocalStorage.Token);
        dispatch(onLogout());
        throw new Error("Phiên đăng nhập đã hết hạn");
      }

      dispatch(onGetProfile());
      // TODO: refresh token if necessary
    } catch (error) {
      console.error(error);
      window.localStorage.removeItem(COMMON.LocalStorage.Token);
      const pathName = window.location.href;
      if (pathName.indexOf("login") === -1) {
        window.location.href = `${COMMON.url_auth}/#/login`;
      }
    }
  }, [token, history, dispatch]);

  useEffect(() => {
    if (isEmpty(device)) {
      dispatch(onDeviceInit());
    }
  }, [device, dispatch]);

  return <Routes />;
}

export default Root;
