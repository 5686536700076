/* eslint-disable no-use-before-define */
import React, { useMemo, useState, useEffect } from 'react';
import {
  Menu, Input, Layout, Button, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import {
  NavLink, Link, useLocation, useHistory,
} from 'react-router-dom';
import qs from 'qs';
import { get } from 'lodash';

import getFilterParams from 'utils/getFilterParams';

import LocationSelector from 'states/selectors/common/location';
import LocationActions from 'states/actions/common/location';
import UserInfo from 'components/UserInfo';

import HeaderLogo from 'assets/images/logo.png';
import './Header.scss';

const {
  Footer, Sider, Content, Header,
} = Layout;

export default function HeaderComponent({ collapsed, toggleMenu }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { search, pathname } = location;

  const filters = useMemo(() => getFilterParams(search), [search]);

  const [keyword, changeKeyword] = useState('');
  const [location_id, setLocation] = useState();

  const locations = useSelector(LocationSelector.getList) || [];
  const locationIdData = useSelector(LocationSelector.getLocationId);

  const setLocationId = (locationId) => {
    setLocation(locationId);
    dispatch(LocationActions.onInitLocation(locationId));
    window.localStorage.setItem(process.env.LOCATION_KEY, locationId);
  };

  useEffect(() => {
    try {
      const payload = {
        params: {
          page: 1,
          limit: 100,
        },
      };
      dispatch(LocationActions.onGetList(payload));
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (!locations) return;
    if (locations.length === 0) return;
    if (!locationIdData) {
      const locationId = get(locations[0], 'id');
      setLocationId(locationId);
      return;
    }
    setLocation(locationIdData);
  }, [locations, locationIdData]);

  useEffect(() => {
    if (!filters) return;

    changeKeyword(get(filters, 'title'));
  }, [filters]);

  const handleChangeKeyword = (e) => {
    changeKeyword(e.target.value);
  };

  const onEnterSearch = (event) => {
    event.stopPropagation();

    if (event.keyCode === 13) {
      const params = {
        ...filters,
        title: keyword,
      };

      history.push(`?${qs.stringify(params)}`);
    }
  };

  const handleChangeLocation = (value) => {
    try {
      const locationId = Number(value);
      setLocationId(locationId);

      const params = {
        ...filters,
        location_id: locationId,
      };

      history.push(`?${qs.stringify(params)}`);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Header className={`header ${collapsed ? 'header-toggle' : ''}`}>
      <div className="header-inner">
        <div className="logo">
          <img src={HeaderLogo} alt="logo" />
        </div>
        <div className="header-inner__toggle">
          <Button className="trigger btn-toggle" onClick={toggleMenu}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </div>
        <div className="header-inner__main-header">
          <Select
            className="company_select"
            placeholder="Chọn địa điểm kinh doanh"
            onChange={handleChangeLocation}
            value={location_id}
          >
            {locations.map((item, key) => (
              <Select.Option key={key.toString()} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          <Input
            placeholder="Nhập số chứng từ!"
            // onChange={handleChangeKeyword}
            value={keyword}
            // onKeyDown={onEnterSearch}
            className="input-search"
          />
        </div>
        <div className="header-inner__user-info"><UserInfo /></div>
      </div>
    </Header>
  );
}
