import { get, omit, cloneDeep } from 'lodash';
import { OptionActionTypes } from 'states/actions/common/option';

const init = {
  loading: true,
  detail: null,
  processing: false,
};

function handleUpdate({ state, action }) {
  const list = cloneDeep(state.list);
  const detailData = get(action, 'payload.data');
  const index = list.findIndex((i) => i.id === detailData.id);
  if (index !== -1) {
    list[index] = { ...list[index], ...detailData };
  }
  return {
    ...state,
    processing: false,
    list,
    detail: {
      ...state.detail,
      data: { ...get(state, 'detail.data'), ...detailData },
    },
  };
}

export default function (state = init, action) {
  switch (action.type) {
    case OptionActionTypes.CLEAR_DETAIL:
      return {
        ...state,
        detail: null,
        loadingDetail: true,
      };

    case OptionActionTypes.CLEAR_STATE:
      return {
        ...init,
      };

    case OptionActionTypes.GET_LIST:
      return {
        ...state,
        loading: true,
      };

    case OptionActionTypes.GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        apiResultGetList: omit(get(action, 'payload'), ['data']),
      };

    case OptionActionTypes.GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: get(action, 'payload.data.data', []),
        metadata: omit(get(action, 'payload.data'), ['data']),
        apiResultGetList: omit(get(action, 'payload'), ['data']),
      };

    case OptionActionTypes.GET_DETAIL:
      return {
        ...state,
        loadingDetail: true,
        detail: null,
      };

    case OptionActionTypes.GET_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetail: false,
        detail: action.payload,
      };

    case OptionActionTypes.GET_DETAIL_ERROR:
      return {
        ...state,
        loadingDetail: false,
        detail: action.payload,
      };

    case OptionActionTypes.CREATE:
    case OptionActionTypes.UPDATE:
    case OptionActionTypes.DELETE:
      return {
        ...state,
        processing: true,
      };

    case OptionActionTypes.CREATE_ERROR:
    case OptionActionTypes.UPDATE_ERROR:
    case OptionActionTypes.DELETE_ERROR:
      return {
        ...state,
        processing: false,
      };
    case OptionActionTypes.UPDATE_SUCCESS:
      return handleUpdate({ state, action });

    case OptionActionTypes.CREATE_SUCCESS:
    case OptionActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
}
