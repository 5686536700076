import React from 'react';
import { Skeleton } from 'antd';
import MainBlock from './Layout/MainBlock';

export default function Loading() {
  return (
    <div className="main-content-inner loan-page">
      <MainBlock>
        <Skeleton active />
      </MainBlock>
    </div>
  );
}
