import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { LocalStorage } from 'constants/common';
import auth from './auth';
import profile from './profile';
import common from './common';

const createAppReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  common,
  profile,
});

const createRootReducer = (history) => (state, action) => {
  if (action.type === 'LOGOUT') {
    window.localStorage.removeItem(LocalStorage.Token);
    window.localStorage.removeItem(LocalStorage.Device);
    return {
      router: state.router,
    };
  }
  return createAppReducer(history)(state, action);
};

export default createRootReducer;
