import { endpoint } from 'constants/common';
import api from 'states/drivers';

const OptionEndpoint = `${endpoint}/options`;

const OptionApi = {
  getList: async ({ params }) => await api.get(`${OptionEndpoint}`, params),
  getDetail: async ({ id }) => await api.get(`${OptionEndpoint}/${id}`),
  create: async ({ params }) => await api.post(`${OptionEndpoint}`, params),
  update: async ({ id, params }) => await api.put(`${OptionEndpoint}/${id}`, params),
  delete: async ({ id }) => await api.delete(`${OptionEndpoint}/${id}`),
};

export default OptionApi;
