import { endpoint } from 'constants/common';
import api from '../../drivers';

const JobEndpoint = `${endpoint}/job`;
const CategoryEndpoint = `${JobEndpoint}/profile`;

const CategoryApi = {
  getList: async ({ params }) => await api.get(`${CategoryEndpoint}`, params),
  getDetail: async ({ id }) => await api.get(`${CategoryEndpoint}/${id}`),
  createDetail: async ({ id, params }) => await api.post(`${CategoryEndpoint}/id/details`, params),
  create: async ({ params }) => await api.post(`${CategoryEndpoint}`, params),
  update: async ({ id, params }) => await api.put(`${CategoryEndpoint}/${id}`, params),
  delete: async ({ id }) => await api.delete(`${CategoryEndpoint}/${id}`),
};

export default CategoryApi;
