import { get, omit, cloneDeep } from 'lodash';
import { CommonActionTypes } from 'states/actions/common/common';

const init = {
  loadingMore: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case CommonActionTypes.COMMON_LOADING_MORE:
      return {
        ...state,
        loadingMore: get(action, 'loading'),
      };
    default:
      return state;
  }
}
