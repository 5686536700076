import { combineReducers } from 'redux';
import init from './common';
import option from './option';
import location from './location';

export default combineReducers({
  init,
  option,
  location,
});
