export const LocationActionTypes = {
  INIT_LOCATION: 'INIT_LOCATION',

  GET_LIST: 'GET_LIST',
  GET_LIST_SUCCESS: 'GET_LIST_SUCCESS',
  GET_LIST_ERROR: 'GET_LIST_ERROR',

  GET_DETAIL: 'GET_DETAIL',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_SUCCESS',
  GET_DETAIL_ERROR: 'GET_DETAIL_ERROR',

  CREATE: 'CREATE',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_ERROR: 'CREATE_ERROR',

  UPDATE: 'UPDATE',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_ERROR: 'UPDATE_ERROR',

  DELETE: 'DELETE',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_ERROR: 'DELETE_ERROR',

  CLEAR_DETAIL: 'CLEAR_DETAIL',
  CLEAR_STATE: 'CLEAR_STATE',
};

Object.keys(LocationActionTypes).forEach((key) => {
  LocationActionTypes[key] = `COMMON_LOCATION_${LocationActionTypes[key]}`;
});

const onClearDetail = () => ({
  type: LocationActionTypes.CLEAR_DETAIL,
});

const onClearState = () => ({
  type: LocationActionTypes.CLEAR_STATE,
});

const onInitLocation = (payload) => ({
  type: LocationActionTypes.INIT_LOCATION,
  payload,
});

const onGetList = (payload) => ({
  type: LocationActionTypes.GET_LIST,
  payload,
});

const onGetListSuccess = (payload) => ({
  type: LocationActionTypes.GET_LIST_SUCCESS,
  payload,
});

const onGetListError = (error) => ({
  type: LocationActionTypes.GET_LIST_ERROR,
  payload: error,
});

/**
 *
 * @param String id
 */
const onGetDetail = ({ id }) => ({
  type: LocationActionTypes.GET_DETAIL,
  payload: { id },
});

const onGetDetailSuccess = (detail) => ({
  type: LocationActionTypes.GET_DETAIL_SUCCESS,
  payload: detail,
});

const onGetDetailError = (error) => ({
  type: LocationActionTypes.GET_DETAIL_ERROR,
  payload: error,
});

/**
 *
 * create
 */
const onCreate = ({ params, filters, callback }) => ({
  type: LocationActionTypes.CREATE,
  payload: { params },
  filters,
  callback,
});

const onCreateSuccess = (detail) => ({
  type: LocationActionTypes.CREATE_SUCCESS,
  payload: detail,
});

const onCreateError = (error) => ({
  type: LocationActionTypes.CREATE_ERROR,
  payload: error,
});

/**
 *
 * update
 */
const onUpdate = ({
  id, params, filters, callback,
}) => ({
  type: LocationActionTypes.UPDATE,
  payload: { id, params },
  filters,
  callback,
});

const onUpdateSuccess = (detail) => ({
  type: LocationActionTypes.UPDATE_SUCCESS,
  payload: detail,
});

const onUpdateError = (error) => ({
  type: LocationActionTypes.UPDATE_ERROR,
  payload: error,
});

/**
 *
 * delete
 */
const onDelete = ({
  id, params, filters, callback,
}) => ({
  type: LocationActionTypes.DELETE,
  payload: { id, params },
  filters,
  callback,
});

const onDeleteSuccess = (detail) => ({
  type: LocationActionTypes.DELETE_SUCCESS,
  payload: detail,
});

const onDeleteError = (error) => ({
  type: LocationActionTypes.DELETE_ERROR,
  payload: error,
});

const LocationActions = {
  onClearDetail,
  onInitLocation,
  onClearState,

  onGetList,
  onGetListSuccess,
  onGetListError,

  onGetDetail,
  onGetDetailSuccess,
  onGetDetailError,

  onCreate,
  onCreateSuccess,
  onCreateError,

  onUpdate,
  onUpdateSuccess,
  onUpdateError,

  onDelete,
  onDeleteSuccess,
  onDeleteError,
};

export default LocationActions;
