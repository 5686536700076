export const CommonActionTypes = {
  COMMON_LOADING_MORE: 'COMMON_LOADING_MORE',
};

const onLoadingMore = (loading) => ({
  type: CommonActionTypes.COMMON_LOADING_MORE,
  loading,
});

export const CommonActions = {
  onLoadingMore,
};

export default CommonActions;
