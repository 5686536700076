import React, { Component } from "react";
import { Layout, Button } from "antd";
import { connect } from "react-redux";
import { get } from "lodash";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import Header from "components/Header";
import SlideBar from "components/SlideBar";
import { AuthActionState } from "states/reducers/auth";
import { onLogout } from "states/actions/auth";
import * as COMMON from "constants/common";

import MainRoutes from "./MainRoutes";
import HeaderLogo from "../assets/images/logo.png";

import "./Main.scss";
import "./main.css";

const { Footer, Sider, Content } = Layout;

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResponsive);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.authActionState !== this.props.authActionState &&
      this.props.authActionState === AuthActionState.TokenError
    ) {
      const pathName = window.location.href;
      if (pathName.indexOf("login") === -1) {
        window.location.href = `${COMMON.url_auth}/#/login`;
      }

      window.localStorage.clear();
      this.props.onLogout();
    }
  }

  handleResponsive = () => {
    const width = window.innerWidth;
    if (width <= 768 && this.state.collapsed === false) {
      this.setState({
        collapsed: true,
      });
    }
  };

  toggleMenu = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed,
    });
  };

  render() {
    return (
      <Layout>
        <Layout className="site-layout">
          <Content
            className={`site-layout-background main-content ${
              this.state.collapsed ? "main-toggle" : ""
            }`}
          >
            <MainRoutes />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    authActionState: get(state, "auth.actionState"),
  }),
  { onLogout }
)(Main);
