import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  TOKEN_ERROR,
  LOGOUT,
  GET_PROFILE_ERROR,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
} from "states/actions/auth";
import { LocalStorage } from "constants/common";

export const AuthActionState = {
  LoginSuccess: LOGIN_SUCCESS,
  LoginError: LOGIN_ERROR,
  TokenError: TOKEN_ERROR,
  NeedLogout: LOGOUT,
  loading: true,
};

const INITIAL_STATE = {
  profile: {},
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLogin: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: false,
        actionState: AuthActionState.LoginSuccess,
        ...action.payload,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isLogin: false,
        actionState: AuthActionState.LoginError,
        timeError: new Date().getTime()
      };
    case TOKEN_ERROR:
      return {
        ...state,
        actionState: AuthActionState.TokenError,
      };
    case GET_PROFILE:
      return { ...state };
    case GET_PROFILE_SUCCESS:
      return { ...state, profile: action.dataProfile, loading: false };
    case GET_PROFILE_ERROR:
      return { ...state, loading: false };
    case LOGOUT:
      window.localStorage.removeItem(LocalStorage.Token);
      window.localStorage.removeItem(LocalStorage.Device);
      return {
        ...state,
        actionState: null,
      };
    default:
      return state;
  }
}
