export const ProfileActionTypes = {
  GET_LIST: 'GET_LIST',
  GET_LIST_SUCCESS: 'GET_LIST_SUCCESS',
  GET_LIST_ERROR: 'GET_LIST_ERROR',

  GET_DETAIL: 'GET_DETAIL',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_SUCCESS',
  GET_DETAIL_ERROR: 'GET_DETAIL_ERROR',

  GET_BALANCE: 'GET_BALANCE',
  GET_BALANCE_SUCCESS: 'GET_BALANCE_SUCCESS',
  GET_BALANCE_ERROR: 'GET_BALANCE_ERROR',

  CREATE: 'CREATE',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_ERROR: 'CREATE_ERROR',

  CREATE_DETAIL: 'CREATE_DETAIL',
  CREATE_DETAIL_SUCCESS: 'CREATE_DETAIL_SUCCESS',
  CREATE_DETAIL_ERROR: 'CREATE_DETAIL_ERROR',

  UPDATE: 'UPDATE',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_ERROR: 'UPDATE_ERROR',

  DELETE: 'DELETE',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_ERROR: 'DELETE_ERROR',

  CLEAR_DETAIL: 'CLEAR_DETAIL',
  CLEAR_STATE: 'CLEAR_STATE',
};

Object.keys(ProfileActionTypes).forEach((key) => {
  ProfileActionTypes[key] = `JOB_PROFILE_${ProfileActionTypes[key]}`;
});

const onClearDetail = () => ({
  type: ProfileActionTypes.CLEAR_DETAIL,
});

const onClearState = () => ({
  type: ProfileActionTypes.CLEAR_STATE,
});

const onGetList = (payload) => ({
  type: ProfileActionTypes.GET_LIST,
  payload,
});

const onGetListSuccess = (payload) => ({
  type: ProfileActionTypes.GET_LIST_SUCCESS,
  payload,
});

const onGetListError = (error) => ({
  type: ProfileActionTypes.GET_LIST_ERROR,
  payload: error,
});

/**
 *
 * @param String id
 */
const onGetDetail = (id) => ({
  type: ProfileActionTypes.GET_DETAIL,
  id,
});

const onGetDetailSuccess = (detail) => ({
  type: ProfileActionTypes.GET_DETAIL_SUCCESS,
  payload: detail,
});

const onGetDetailError = (error) => ({
  type: ProfileActionTypes.GET_DETAIL_ERROR,
  payload: error,
});

/**
 * Get balance
 */

const onGetBalance = (payload) => ({
  type: ProfileActionTypes.GET_BALANCE,
  payload,
});

const onGetBalanceSuccess = (detail) => ({
  type: ProfileActionTypes.GET_BALANCE_SUCCESS,
  payload: detail,
});

const onGetBalanceError = (error) => ({
  type: ProfileActionTypes.GET_BALANCE,
  payload: error,
});

/**
 *
 * create
 */
const onCreate = ({ params, filters, callback }) => ({
  type: ProfileActionTypes.CREATE,
  payload: { params },
  filters,
  callback,
});

const onCreateSuccess = (detail) => ({
  type: ProfileActionTypes.CREATE_SUCCESS,
  payload: detail,
});

const onCreateError = (error) => ({
  type: ProfileActionTypes.CREATE_ERROR,
  payload: error,
});

/**
 *
 * create detail
 */
const onCreateDetail = ({ params, filters, callback }) => ({
  type: ProfileActionTypes.CREATE_DETAIL,
  payload: { params },
  filters,
  callback,
});

const onCreateDetailSuccess = (detail) => ({
  type: ProfileActionTypes.CREATE_DETAIL_SUCCESS,
  payload: detail,
});

const onCreateDetailError = (error) => ({
  type: ProfileActionTypes.CREATE_DETAIL_ERROR,
  payload: error,
});

/**
 *
 * update
 */
const onUpdate = ({
  id, params, filters, callback,
}) => ({
  type: ProfileActionTypes.UPDATE,
  payload: { id, params },
  filters,
  callback,
});

const onUpdateSuccess = (detail) => ({
  type: ProfileActionTypes.UPDATE_SUCCESS,
  payload: detail,
});

const onUpdateError = (error) => ({
  type: ProfileActionTypes.UPDATE_ERROR,
  payload: error,
});

/**
 *
 * delete
 */
const onDelete = ({ id, filters, callback }) => ({
  type: ProfileActionTypes.DELETE,
  id,
  filters,
  callback,
});

const onDeleteSuccess = (detail) => ({
  type: ProfileActionTypes.DELETE_SUCCESS,
  payload: detail,
});

const onDeleteError = (error) => ({
  type: ProfileActionTypes.DELETE_ERROR,
  payload: error,
});

const ProfileActions = {
  onClearDetail,

  onClearState,

  onGetList,
  onGetListSuccess,
  onGetListError,

  onGetDetail,
  onGetDetailSuccess,
  onGetDetailError,

  onCreate,
  onCreateSuccess,
  onCreateError,

  onCreateDetail,
  onCreateDetailSuccess,
  onCreateDetailError,

  onUpdate,
  onUpdateSuccess,
  onUpdateError,

  onDelete,
  onDeleteSuccess,
  onDeleteError,

  onGetBalance,
  onGetBalanceSuccess,
  onGetBalanceError,
};

export default ProfileActions;
