import React, { Component, useMemo, useEffect, useState } from "react";
import {
  Button,
  Input,
  Form,
  Layout,
  Card,
  Checkbox,
  message,
  Spin,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { onLogin } from "states/actions/auth";
import { AuthActionState } from "states/reducers/auth";
import * as COMMON from "constants/common";
import getFilterParams from "utils/getFilterParams";

import HeaderLogo from "../assets/images/logo.png";
import "./Login.scss";

const { Footer, Sider, Content } = Layout;

export default function Login() {
  const location = useLocation();
  const dispatch = useDispatch();

  const history = useHistory();
  const { search, pathname } = location;

  const filters = useMemo(() => getFilterParams(search), [search]);

  const authActionState = useSelector((state) =>
    get(state, "auth.actionState")
  );
  const [loading, setLoading] = useState(false);

  const timeError = useSelector((state) => get(state, "auth.timeError"));

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);
    dispatch(onLogin({ ...values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    try {
      window.localStorage.clear();
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    // effect
    try {
      const token = window.localStorage.getItem(COMMON.LocalStorage.Token);
      const callback = get(filters, "callback");
      if (authActionState === AuthActionState.LoginSuccess) {
        window.location.href = `${COMMON.url_hostname}?token=${token}&location_id=1&stock_id=6`;
      }

      console.log("COMMON.url_hostname", COMMON.url_hostname);

      if (authActionState === AuthActionState.LoginError) {
        message.error("Tài khoản không tồn tại hoặc sai thông ");
      }
      setLoading(false);
    } catch (err) {
      
      console.log(err);
      setLoading(false);
      message.error("Tài khoản không tồn tại hoặc sai thông ");
    }
  }, [authActionState, timeError, history]);

  return (
    <Layout style={{ height: "100%" }}>
      <Layout className="container">
        <Content breakpoint="lg" className=" main-content">
          <div className="login-page">
            <Card className="card-login">
              <div className="login-logo">
                <img src={HeaderLogo} alt="ibe" />
              </div>
              <Spin tip="loading" spinning={loading}>
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Checkbox style={{ marginBottom: 10 }}>Remember me</Checkbox>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Log in
                    </Button>
                  </Form.Item>
                  <a className="btn-home" href={COMMON.url_hostname}>
                    Trở về trang chủ
                  </a>
                </Form>
              </Spin>
            </Card>
          </div>
        </Content>
      </Layout>
      <Footer className="footer" />
    </Layout>
  );
}
